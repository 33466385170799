<template>
  <div class="page-container surgery-reports-page">
    <portal to="page-name">Relatórios</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Relatórios</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <ul class="menu">
              <li class="menu-item" :class="menu.selected ? 'text-primary' : ''"
                  v-for="(menu, i) in menus" :key="i"
                  @click="openPrint(menu.path)">
                {{ menu.label }}
              </li>
            </ul>
          </div>
          <div class="column col-8 col-md-12 col-sm-12 form-group">
            <template v-if="canAccess">
              <surgery-map
                v-if="selected === 'surgery-map'"
              />
            </template>
            <forbidden v-else></forbidden>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { REPORT_PRODUCT_MANAGEMENT } from '@/data/actions/modules/stock';
import Forbidden from '@/components/auth/Forbidden.vue';
import SurgeryMap from './SurgeryMap.vue';

export default {
  components: {
    Forbidden,
    SurgeryMap,
  },
  data() {
    return {
      selected: 'surgery-map',
      insurances: [],
      professionals: [],
      expenses: [],
      menus: [
        {
          path: 'surgery-map',
          label: 'Mapa Cirúrgico',
          selected: true,
          // permission: REPORT_PRODUCT_MANAGEMENT,
        },
      ],
    };
  },
  mounted() {},
  computed: {
    canAccess() {
      const currentMenu = this.menus.find(({ path }) => path === this.selected);

      if (currentMenu && currentMenu.permission) {
        return this.$can(currentMenu.permission);
      }
      return true;
    },
  },
  methods: {
    openPrint(path) {
      this.selected = path;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.surgery-reports-page {
  .menu {
    border-right: $border-width solid $border-color;
    box-shadow: none;
    li {
      padding: $layout-spacing 0;
      border-bottom: $border-width solid $border-color;
      &:hover {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
}
</style>
