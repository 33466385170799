<template>
  <div class="product-transaction-page">
    <h6>Relatório de Mapa Cirúrgico</h6>
    <div class="columns">
      <div class="column col-4 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.startDate.$error}">
        <label class="form-label">Data início</label>
        <div class="input-group">
          <dx-input-date
            id="appointment-patient-birth-date"
            class="form-input text-center"
            v-model="filters.startDate"
            @blur="$v.filters.startDate.$touch()"
          />
        </div>
        <template v-if="$v.filters.startDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.startDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.startDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-4 col-md-4 col-sm-12 form-group"
           :class="{'has-error': $v.filters.endDate.$error}">
        <label class="form-label">Data término</label>
        <dx-input-date
          id="end-date"
          class="form-input text-center"
          v-model="filters.endDate"
          @blur="$v.filters.endDate.$touch()"
        />
        <template v-if="$v.filters.endDate.$error">
          <div class="form-input-hint"
               v-if="!$v.filters.endDate.required">Campo obrigatório</div>
          <div class="form-input-hint"
               v-else-if="!$v.filters.endDate.date" >Data inválida</div>
        </template>
      </div>
      <div class="column col-12 form-group">
        <label class="form-label">Sala</label>
        <select id="place" class="form-select" v-model="filters.scheduleId">
          <option value="">[Todas as salas]</option>
          <option v-for="(item, i) in schedules" :value="item.id" :key="i">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="footer">
      <button class="btn btn-primary btn-icon-left"
              :class="{loading: printing}"
              @click="print" :disabled="printing">
        <fa-icon :icon="['fal', 'print']"></fa-icon>Imprimir
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';

export default {
  data() {
    return {
      printing: false,
      schedules: [],
      filters: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        scheduleId: '',
      },
    };
  },
  mounted() {
    this.loadSchedules();
  },
  validations() {
    const rules = {
      filters: {
        startDate: { date, required },
        endDate: { date, required },
      },
    };
    if (this.filters.startDate) {
      rules.filters.endDate = {
        date,
        required,
        minDate: minDate(this.filters.startDate),
      };
    }

    return rules;
  },
  methods: {
    print() {
      this.$v.filters.$touch();
      if (this.$v.filters.$error) {
        return false;
      }

      this.printing = true;

      const params = {
        startDate: this.filters.startDate,
        endDate: this.filters.endDate,
      };

      if (this.filters.scheduleId) {
        params.scheduleId = this.filters.scheduleId;
      }

      return this.$file
        .print('/surgery-centers/surgery-map-print', params)
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    loadSchedules() {
      this.schedules = [];

      const params = {
        active: true,
        type: 'surgery',
        limit: 0,
      };

      return this.$http
        .get('/schedules', { params })
        .then(({ data }) => {
          this.schedules = data.items;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
  @import "../../../assets/scss/variables";
  .product-transaction-page {
    .footer {
      margin-top: $layout-spacing-lg;
      text-align: right;
    }
  }
</style>
